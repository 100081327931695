<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
            :disabled="disabled">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import EvaluationService from "@/services/EvaluationService";

	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
			disabled: {
                type: Boolean,
                default: false
            },
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		data() {
			return {
				selected: this.value,
				options: []
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				const config = {
					params: {
						limit: -1
					},
				};
				this.options = [];
				EvaluationService.getAll(config)
				           .then(response => {
                               const data = response.data.data;
					           data.map(item => {
						           this.options.push({
							           value: item.id,
							           text: item.name,
						           });
					           });
				           })
				           .then(() => {
					           this.selected = this.setSelected(this.value, this.options)
				           })
			}
		}
	}
</script>
